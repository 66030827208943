<template>
  <v-card>
    <v-card-title>
      {{ mode === 'edit' ? 'Editar' : 'Adicionar' }}
      <v-spacer />
      <v-btn
        icon
        color="error"
      >
        <v-icon
          color="error"
          ml-5
          @click="$emit('close')"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-container v-if="mode === 'edit'">
      <v-card>
        <v-col>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="modelsData.data.user.name"
                label="Funcionário"
                :items="optionsEmployee"
                :rules="rolesRequired"
                outlined
                readonly
                dense
              />
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col>
              <v-text-field
                v-model="modelsData.data.goal"
                label="Meta"
                :rules="rolesRequired"
                outlined
                dense
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="modelsData.data.goal_security"
                label="Segurança"
                :rules="rolesRequired"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-card
            class="d-flex bd-highlight mb-3"
            md="12"
            lg="12"
          >
            <v-card
              outlined
              class="mr-auto p-2 bd-highlight"
              md="12"
            >
              <v-col>
                <v-autocomplete
                  v-model="commissionValue"
                  label="Cálculo da meta"
                  :items="optionsCalculationTarget"
                  :rules="rolesRequired"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_commission_standard.critical_level"
                  label="Até 9"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_commission_standard.below_goal"
                  label="Até 12"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_commission_standard.goal"
                  label="Meta"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_commission_standard.credit_letter"
                  label="Carta"
                  outlined
                  dense
                />
              </v-col>
            </v-card>
            <v-card
              outlined
              cols="12"
              md="12"
              lg="12"
            >
              <v-col>
                <v-autocomplete
                  v-model="bonusValue"
                  label="Cálculo da bonificação"
                  :items="optionsCalculationBonus"
                  :rules="rolesRequired"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_bonus_standard.parcel_2"
                  label="2° parcela"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_bonus_standard.parcel_3"
                  label="3° parcela"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_bonus_standard.parcel_4"
                  label="4° parcela"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="modelsData.data.cnh_bonus_standard.delivery"
                  label="Entrega"
                  outlined
                  dense
                />
              </v-col>
            </v-card>
          </v-card>
        </v-col>
        <v-col class="d-flex flex-row-reverse">
          <v-btn
            color="info"
            @click="updateCommissionById()"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-card>
    </v-container>

    <v-container v-else>
      <v-card>
        <v-col>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="employees"
                label="Funcionário"
                :items="listEmployees[0]"
                :rules="rolesRequired"
                item-text="name"
                item-value="cpf"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col>
              <v-text-field
                v-model="addConfigCommission.goal"
                :rules="rolesRequired"
                label="Meta"
                outlined
                dense
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="addConfigCommission.goal_security"
                :rules="rolesRequired"
                label="Segurança"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-card
            class="d-flex bd-highlight mb-3"
            md="12"
            lg="12"
          >
            <v-card
              outlined
              class="mr-auto p-2 bd-highlight"
              md="12"
            >
              <v-col>
                <v-autocomplete
                  v-model="commissionValue"
                  :items="optionsCalculationTarget"
                  :rules="rolesRequired"
                  label="Cálculo da meta"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsCommission.critical_level"
                  readonly
                  label="Até 9"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsCommission.below_goal"
                  readonly
                  label="Até 12"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsCommission.goal"
                  readonly
                  label="Meta"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsCommission.credit_letter"
                  readonly
                  label="Carta"
                  outlined
                  dense
                />
              </v-col>
            </v-card>
            <v-card
              outlined
              cols="12"
              md="12"
              lg="12"
            >
              <v-col>
                <v-autocomplete
                  v-model="bonusValue"
                  :items="optionsCalculationBonus"
                  :rules="rolesRequired"
                  label="Cálculo da bonificação"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsBonus.parcel_2"
                  readonly
                  label="2° parcela"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsBonus.parcel_3"
                  readonly
                  label="3° parcela"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsBonus.parcel_4"
                  readonly
                  label="4° parcela"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="valuesFieldsBonus.delivery"
                  readonly
                  label="Entrega"
                  outlined
                  dense
                />
              </v-col>
            </v-card>
          </v-card>
        </v-col>
        <v-col class="d-flex flex-row-reverse">
          <v-btn
            color="info"
            @click="addNewConfigCommission()"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-card>
    </v-container>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    modelsData: {
      type: Object,
      required: true,
      default: () => {},
    },
    mode: {
      type: String,
      required: true,
    },
    updatedTable: {
      type: Function,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: true,
    },

  },

  data() {
    return {
      icons: {
        mdiClose,
      },
      modeModal: this.mode,
      editValue: '',
      rolesRequired: [r => !!r || 'Campo obrigatório'],
      valuesFieldsCommission: {
        critical_level: '',
        credit_letter: '',
        goal: '',
        below_goal: '',
      },
      valuesFieldsBonus: {
        parcel_2: '',
        parcel_3: '',
        parcel_4: '',
        delivery: '',
      },
      commissionValue: '',
      bonusValue: '',
      nameValue: '',
      goalValue: '',
      goalSecurityValue: '',
      addConfigCommission: {
        name: '',
        goal: '',
        goal_security: '',
      },

      listEmployees: [],
      listTargets: [],
      listBonus: [],
      calculationTarget: '',
      employees: [],
    }
  },

  computed: {
    optionsEmployee() {
      const listNameEmployees = []

      this.listEmployees.map(items => {
        items.map(item => {
          listNameEmployees.push(item.name)
        })
      })

      return listNameEmployees
    },

    optionsCalculationTarget() {
      const listNameTarget = []

      this.listTargets.map(items => {
        items.map(item => {
          listNameTarget.push(item.description)
        })
      })

      return listNameTarget
    },

    optionsCalculationBonus() {
      const listNameBonus = []

      this.listBonus.map(items => {
        items.map(item => {
          listNameBonus.push(item.description)
        })
      })

      return listNameBonus
    },

  },

  watch: {
    optionsCalculationTarget() {
    },

    modeModal() {
    },

    editValue() {
    },

    commissionValue(n) {
      const idCommission = []

      this.listTargets.map(items => {
        items.map(item => {
          // eslint-disable-next-line no-unused-expressions
          item.description === n ? idCommission.push(item.id) : null
        })
      })

      axiosIns.get(`api/v1/integration/cnh_commission_standard/edit/${idCommission[0]}`)
        .then(res => {
          this.valuesFieldsCommission.critical_level = res.data.data.critical_level
          this.valuesFieldsCommission.credit_letter = res.data.data.credit_letter
          this.valuesFieldsCommission.goal = res.data.data.goal
          this.valuesFieldsCommission.below_goal = res.data.data.below_goal

          this.modelsData.data.cnh_commission_standard.critical_level = res.data.data.critical_level
          this.modelsData.data.cnh_commission_standard.below_goal = res.data.data.below_goal
          this.modelsData.data.cnh_commission_standard.goal = res.data.data.goal
          this.modelsData.data.cnh_commission_standard.credit_letter = res.data.data.credit_letter
        })
    },

    bonusValue(n) {
      const idBonus = []

      this.listBonus.map(items => {
        items.map(item => {
          // eslint-disable-next-line no-unused-expressions
          item.description === n ? idBonus.push(item.id) : null
        })
      })

      axiosIns.get(`api/v1/integration/cnh_bonus_standard/edit/${idBonus[0]}`)
        .then(res => {
          this.valuesFieldsBonus.parcel_2 = res.data.data.parcel_2
          this.valuesFieldsBonus.parcel_3 = res.data.data.parcel_3
          this.valuesFieldsBonus.parcel_4 = res.data.data.parcel_4
          this.valuesFieldsBonus.delivery = res.data.data.delivery

          this.modelsData.data.cnh_bonus_standard.parcel_2 = res.data.data.parcel_2
          this.modelsData.data.cnh_bonus_standard.parcel_3 = res.data.data.parcel_3
          this.modelsData.data.cnh_bonus_standard.parcel_4 = res.data.data.parcel_4
          this.modelsData.data.cnh_bonus_standard.delivery = res.data.data.delivery
        })
    },

  },

  created() {
    this.getItemsListEmployees()
    this.getItemsListTarget()
    this.getItemsListBonus()
    this.editValue = this.modelsData.data.cnh_commission_standard.description
  },

  methods: {
    async updateCommissionById() {
      const body = {
        vendor_cpf: this.modelsData.data.user.cpf,
        goal: this.modelsData.data.goal,
        goal_security: this.modelsData.data.goal_security,
        regions_id: this.modelsData.data.regions_id,
        companies_id: this.modelsData.data.companies_id,
        cnh_commission_standards_id: this.modelsData.data.cnh_commission_standards_id,
        cnh_bonus_standards_id: this.modelsData.data.cnh_bonus_standards_id,
      }

      await axiosIns.put(`api/v1/integration/cnh_parameter/update/${this.modelsData.data.id}`, body)
        .then(
          async () => {
            this.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Dados da comissão atualizados!',
              showConfirmButton: false,
              timer: 2000,
            })
            this.closeModal()
            this.updatedTable()
          },
        )
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados!',
            showConfirmButton: false,
            text: error,
          })
          this.closeModal()
        })
      await this.updatedTable()
    },

    async getItemsListEmployees() {
      await axiosIns.get('api/v1/records/user/index')
        .then(res => {
          this.listEmployees.push(res.data.data)
        })
    },

    async getItemsListTarget() {
      await axiosIns('api/v1/integration/cnh_commission_standard/index')
        .then(res => {
          this.listTargets.push(res.data.data)
        })
    },

    async getItemsListBonus() {
      await axiosIns.get('api/v1/integration/cnh_bonus_standard/index')
        .then(res => {
          this.listBonus.push(res.data.data)
        })
    },

    async addNewConfigCommission() {
      const idCommissionStandards = []
      const idBonusStandard = []

      this.listTargets.map(items => {
        items.map(item => {
          // eslint-disable-next-line no-unused-expressions
          item.description === this.commissionValue ? idCommissionStandards.push(item.id) : null
        })
      })

      this.listBonus.map(items => {
        items.map(item => {
          // eslint-disable-next-line no-unused-expressions
          item.description === this.bonusValue ? idBonusStandard.push(item.id) : null
        })
      })

      const body = {
        vendor_cpf: this.employees,
        goal: this.addConfigCommission.goal,
        goal_security: this.addConfigCommission.goal_security,
        cnh_commission_standards_id: idCommissionStandards[0],
        cnh_bonus_standards_id: idBonusStandard[0],
      }

      await axiosIns.post('api/v1/integration/cnh_parameter/store/', body)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados de configurações salvos!',
            showConfirmButton: false,
            timer: 2000,
          })
          this.addConfigCommission = {
            name: '',
            goal: '',
            goal_security: '',
          }
          this.commissionValue = ''
          this.bonusValue = ''

          this.closeModal()
          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao adicionar dados!',
            showConfirmButton: false,
            text: error,
            timer: 5000,
          })
          this.closeModal()
        })
    },
  },

}
</script>
