<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        <div class="mr-auto p-2 bd-highlight">
          Vendedor
        </div>

        <v-btn
          color="info"
          class="p-2 bd-highlight"
          @click="openModalAddCommission()"
        >
          Adicionar
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data_table"
        group-by="region"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
        hide-default-footer
        :page.sync="pageOptions.page"
        @page-count="pageCount = $event"
      >
        <template
          v-slot:group.header="{items}"
        >
          <td
            colspan="10"
          >
            {{ items[0].region }}
          </td>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.id === loadingSpinner ? false : true"
            medium
            class="me-2"
            @click="getItemVendorById(item.id)"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === loadingSpinner ? true : false"
            indeterminate
            :size="25"
            color="gray"
          >
          </v-progress-circular>
          <v-icon
            medium
            color="error"
            class="me-2"
            @click="openModalDeleteVendor(item.id)"
          >
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>
      <v-card-text>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageOptions.page"
              total-visible="5"
              :length="pageOptions.pageCount"
              @input="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="openModalDelete"
      max-width="290"
    >
      <v-card>
        <v-container>
          <v-card-title class="text-h5">
            Excluindo vendedor
          </v-card-title>
          <v-card-text>Você tem certeza que deseja excluir este vendedor?</v-card-text>
          <v-card-text>Esta ação não poderá ser revertida!</v-card-text>
          <v-card-actions class="d-flex flex-row-reverse bd-highlight">
            <v-btn
              color="error"
              @click="deleteVendorConfig()"
            >
              Excluir
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openModal"
      width="600"
    >
      <commission-config-modal
        :models-data="dataToModalById"
        :mode="mode"
        :updated-table="updatedTable"
        :close-modal="closeModal"
        @close="openModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js'
import commissionConfigModal from './commissionConfigModal.vue'

export default {
  components: {
    commissionConfigModal,
  },
  data() {
    return {
      dataToModalById: [],
      openModalDelete: false,
      loading: true,
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },

      data_table: [],
      openModal: false,
      mode: '',
      loadingSpinner: '',
      icons: {
        mdiPencilOutline,
        mdiDeleteOutline,
      },
      headers: [
        { text: 'FUNCIONARIO', value: 'name' },
        { text: 'META', value: 'goal' },
        { text: 'SEGURANÇA', value: 'goal_security' },
        { text: 'COMISSÃO', value: 'description_commission' },
        { text: 'BONIFICAÇÃO', value: 'description_bonus' },
        { text: 'AÇÕES', value: 'actions' },
      ],
      idToDeleteVendorConfig: '',
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    openModalDeleteVendor(item) {
      this.openModalDelete = true
      this.idToDeleteVendorConfig = item
    },

    async deleteVendorConfig() {
      await axiosIns.delete(`api/v1/integration/cnh_parameter/destroy/${this.idToDeleteVendorConfig}`)
      this.updatedTable()
      this.openModalDelete = false
    },

    async getItemsTable() {
      await axiosIns.get('api/v1/integration/cnh_parameter/filter_parameter')
        .then(res => {
          this.data_table = res.data.data
          this.loading = false
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
        })
    },

    async getItemVendorById(configId) {
      this.loadingSpinner = configId

      await axiosIns(`api/v1/integration/cnh_parameter/edit/${configId}`)
        .then(res => {
          this.dataToModalById = res.data
        })

      this.loadingSpinner = ''
      this.openModal = true
      this.mode = 'edit'
    },

    openModalAddCommission() {
      this.mode = 'add'
      this.openModal = true
    },

    async nextPage() {
      this.loading = true
      await axiosIns.get(`api/v1/integration/cnh_parameter/filter_parameter?page=${this.pageOptions.page}`)
        .then(res => {
          this.data_table = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
    },

    async updatedTable() {
      await this.getItemsTable()
    },
    closeModal() {
      this.openModal = false
    },
  },
}
</script>
